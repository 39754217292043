import React, { useState, useEffect } from 'react';
import LandingHeader from '../../organism/LandingHeader';
import Footer from '../../organism/Footer';
import styles from './index.module.scss';
import '../InstallPWA/styles.scss';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../utils/useWindowSize';
import LandingPageLayout from '../../Layout/LandingPageLayout';
import { Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import IphoneShare from '../../assets/IphoneShare.svg';
import pwaInstall1 from '../../assets/pwaInstall1.svg';
import pwaInstall2 from '../../assets/pwaInstall2.svg';
import DownloadCloseIcon from '../../assets/DownloadCloseIcon.svg';
import InstallDownArrow from '../../assets/InstallDownArrow.svg';
import InstallUpArrow from '../../assets/InstallUpArrow.svg';
import { useGetMyProfile } from '../../services/mutations';
import { routeUrls } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import IconDownloadApp from '../../assets/IconDownloadApp.png';
import landingBannerMobile from '../../assets/landingBannerMobile.svg';
// import AppLogo from '../../assets/AppLogo.svg';
 import AppLogo from '../../assets/MeadowLogo.png';
import IconDownloadApple from '../../assets/IconDownloadApple.svg';
import IconDownloadGoogle from '../../assets/IconDownloadGoogle.svg';
import landingPageMobile from '../../assets/landingPageMobile.svg';
import DownloadPlusIcon from '../../assets/DownloadPlusIcon.svg';
import MacAddIcon from '../../assets/MacAddIcon.svg';
import landingPageCheckBoxFill from '../../assets/landingPageCheckBoxFill.svg';
import accordionExpand from '../../assets/accordionExpand.svg';
import accordionCollapse from '../../assets/accordionCollapse.svg';
import accordionIcon from '../../assets/accordionIcon.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';

import {
  landingPage_how_does_it_work_desc,
  landingPage_capabilities,
  landingPageFAQ,
} from '../../utils/constants';
import DashboardPage from '../DashboardPage';
import YouTube from "react-youtube";

const LandingPage = () => {
  const nav = useNavigate();
  const [expanded, setExpanded] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showSplash, setSplash] = useState(true);
  const [showIosDownload, setShowIosDownload] = useState(false);
  const [showIpad, setShowIpad] = useState(false);
  const [showIphone, setShowIphone] = useState(false);
  const [showMac, setShowMac] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const [IsMacBook, setIsMacBook] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsIPad(!!userAgent.match(/iPad/i));
    setIsIPhone(!!userAgent.match(/iPhone/i));
    setIsMacBook(!!userAgent.match(/Macintosh/i));
  }, []);

  const handleDownloadAppClick = () => {
    if (isIPhone === true) {
      setShowIphone(true);
      setShowIosDownload(true);
      window.scrollTo(0, 0);
    }
    else if (IsMacBook === true) {
      setShowMac(true);
      setShowIosDownload(true);
      window.scrollTo(0, 0);
    }
    else if (isIPad === true) {
      setIsIPad(true);
      setShowIosDownload(true);
      window.scrollTo(0, 0);
    }
    else {
      if (deferredPrompt) {
        try {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            setDeferredPrompt(null);
          });
        } catch (error) {
          console.error('Error prompting installation:', error);
        }
      } else {
        toast.error('App already installed');
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setTimeout(() => {
        setSplash(false);
        window.location.href = '/home';
      }, 2000);
    } else {
      setTimeout(() => {
        setSplash(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      console.log('beforeinstallprompt event:', event);
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        beforeInstallPromptHandler,
      );
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);

  console.log('width', window?.innerWidth, width);

  const handleOnSubmitClicked = () => { };

  const handleMessageChange = (evt) => {
    setMessage(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    console.log('handleChange: ', isExpanded);
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      {showSplash ? (
        <DashboardPage />
      ) : (
        <LandingPageLayout>
          <Box
            component="main"
            style={{ marginTop: `${scaleByRatio(100)}px`, background: '#000' }}>
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                marginTop={'20px'}
                justifyItems={'center'}
                style={{
                  display: `flex`,
                  width: '100%',
                  textAlign: 'center'
                }}>
                <Typography
                  className={`${styles.bannerHead}`}
                  style={{
                    fontSize: `40px`,
                    width: '100%',
                  }}>
                  Introducing Meadow, the positive place, where it's your close friends that help you find love.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              className={`${styles.banner} ${styles.landingHeader}`}
              sx={{
                padding: '40px',
                display: 'flex',
              }}>
              <Grid
                item
                alignItems="center"
                justifyItems={'right'}
                style={{
                  display: `flex`,
                  // paddingRight:`${scaleByRatio(61)}px`,
                  width: '40%',
                }}
                className={styles.firstHeaderGrid}>
                <Grid
                  container
                  direction="column"
                  style={{
                    gap: `${scaleByRatio(32)}px`,
                    // width: `${scaleByRatio(512)}px`
                    width: '100%',
                  }}>


                  <Grid
                    item
                    xs={12}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={styles.bannerDescription}
                    >
                      Meadow is the world’s first dating network, use it on the web or installed on your mobile phone.
                    </Typography>
                  </Grid>


                  <Grid
                    item
                    xs={12}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={styles.bannerDescriptionTwo}
                    >
                      In Meadow, you must have 2 friends attached to your profile. As you and your friends explore the world of Meadow, you can put potential daters “up for discussion” then chat with your friends about the possibilities
                    </Typography>
                  </Grid>


                  <Grid
                    item
                    xs={12}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={styles.bannerDescription}
                    >
                      When you want to approach someone, you have to “Reach Out” to one of their friends first. If after chatting with the friend of the dater they can either make an “Introw” for you, or politely let you know that it’s not a match.
                    </Typography>
                  </Grid>


                  <Grid
                    item
                    xs={12}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={styles.bannerDescriptionTwo}
                    >
                      Having friends help you on the journey replicates the real world in a more realistic way. Having a friend screen for you reduces ghosting and all of the awkward aspects of online dating. Your friends know you best and can give an honest perspective that helps to find connection.
                    </Typography>
                  </Grid>


                </Grid>

              </Grid>

              <Grid
                item
                justifyContent="right"
                alignItems={'top'}
                style={{
                  display: `flex`,
                  width: '55%',
                }}
                className={styles.secHeaderGrid}>
                <Grid>
                  <Grid sx={{ display: getwidth < 640 ? 'none' : 'inherit' }}>
                    <YouTube
                      videoId="t-feShvD3Oc"
                      options={{
                        height: '390',
                        width: '640',
                        playerVars: {
                          autoplay: 1,
                          controls: 0
                        }
                      }}
                      onReady={() => {}}
                      id="video"
                    />;
                  </Grid>


                  <Grid
                    border="1px solid #cccccc"
                    borderRadius="25px"
                    marginTop={'40px'}
                    marginLeft={'40px'}
                    marginRight={'40px'}
                    paddingTop={'40px'}
                    paddingBottom={'13px'}
                    justifyContent='center'
                  >
                    <Grid
                      item
                      xs={12}
                      justifyContent={'center'}
                      style={{
                        display: `flex`,
                        width: '100%',
                      }}>
                      <Typography
                        onClick={() => {
                          handleDownloadAppClick();
                        }}
                        className={styles.downloadAppInstall}
                      >
                        Download and install
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      justifyItems={'center'}
                      style={{
                        display: `flex`,
                        gap: '5px',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        style={{
                          float: `left`,
                        }}
                        className={styles.appStoreImg}>
                        <img
                          src={AppLogo}
                          alt="appstore link"
                          style={{
                            height: `${scaleByRatio(80)}px`,
                          }}
                          onClick={() => {
                            handleDownloadAppClick();
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              <>
                {getwidth < 600 && (
                  <Button
                    variant="outlined"
                    className={styles.btnJoin}
                    onClick={() => nav(routeUrls.join)}
                    style={{
                      background: '#FFF',
                      fontFamily:'Gill Sans Nova',
                      // width: `${scaleByRatio(101)}px`,
                      fontSize: `${scaleByRatio(20)}px`,
                      lineHeight: `${scaleByRatio(24)}px`,
                      marginLeft: '10px',
                      minHeight: '56px',
                      fontWeight: 600,
                      borderRadius: '10px',
                    }}>
                    Sign in/Sign up
                  </Button>
                )}
              </>
            </Grid>

            <Grid sx={{ marginTop: 2 }}>
              <Typography className={styles.orangeHeadline}>
                Meadow, the place where your friends help love to <span>blossom.</span>
              </Typography>
            </Grid>




            <Grid
              container
              direction="column"
              className={styles.mobPadding}
              style={{
                padding: '10px 40px',
                backgroundColor: '#000000',
                color: '#FFFFFF',
              }}>
              {/*<Grid*/}
              {/*  item*/}
              {/*  xs={12}*/}
              {/*  justifyContent="center"*/}
              {/*  alignItems={'center'}*/}
              {/*  style={{*/}
              {/*    display: `flex`,*/}
              {/*    backgroundColor: '#000',*/}
              {/*    color: '#fff',*/}
              {/*  }}>*/}
              {/*  <Typography*/}
              {/*    textAlign={'center'}*/}
              {/*    className={styles.howDoesItWork}*/}
              {/*    sx={{*/}
              {/*      fontSize: `${scaleByRatio(32)}px`,*/}
              {/*      lineHeight: `${scaleByRatio(38.4)}px`,*/}
              {/*      padding: `${scaleByRatio(20)}px ${scaleByRatio(20)}px`,*/}
              {/*    }}>*/}
              {/*    How does it work?*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid>*/}
              {/*  <Typography textAlign="center" className={styles.howDoesItWorkDescOne}>*/}
              {/*    Create Your profile and set your preferences.*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid>*/}
              {/*  <Typography textAlign="center" className={styles.howDoesItWorkDescTwo}>*/}
              {/*    Ask 2 friends to join you on your journey.*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid>*/}
              {/*  <Typography textAlign="center" className={styles.howDoesItWorkDescOne}>*/}
              {/*    You and your friends can then put possible matches <br/>*/}
              {/*    <span className={styles.yellow}>up for discussion</span> in the Friends chat.*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid>*/}
              {/*  <Typography textAlign="center" className={styles.howDoesItWorkDescTwo}>*/}
              {/*    When you want to approach someone you have to <span className={styles.neon}>reach out</span><br />*/}
              {/*    to one the their friends first. If their friend thinks you are a match<br />*/}
              {/*    Then they can make an <span className={styles.orange}>introw</span> and pass you on the dater.*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid>*/}
              {/*  <Typography textAlign="center" className={styles.howDoesItWorkDescOne}>*/}
              {/*    We offer suggested polite dropdown answers if things are going well<br />*/}
              {/*    or as expected to reduce ghosting.*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}




              <Grid sx={{ marginTop: 5 }}>
                <Typography className={styles.DescRed}>
                  Chat With Your Friends
                </Typography>
                <Typography className={styles.DescWhite}>
                  As you and your friends are looking at potential people people you simply add then the  friend chat  by putting the person “up for discussion” . You can review and discuss profiles together and take your time. Get feedback and discuss things , have fun with it and lower the pressure.
                </Typography>

                <Typography className={styles.DescRed}>
                  Your Friends Screen for You
                </Typography>
                <Typography className={styles.DescWhite}>
                  If someone wants to connect with you , they have to chat with one of your friends first. If after some discussion your friend thinks its a match they pass on the person to you by making an “introw” . This reduces ghosting and awkward interactions. On meadow, you never meet a stranger  you are always introduced by a friend.
                </Typography>

                <Typography className={styles.DescRed}>
                  Set your Dating Preferences
                </Typography>
                <Typography className={styles.DescWhite}>
                  Our advanced algorithm shows you the type of person you want to see based on a carefully curated and clear preferences you set. You can edit these as you go along to refine the people you are seeing.
                </Typography>

                <Typography className={styles.DescRed}>
                  Stay Positive and Friendly
                </Typography>
                <Typography className={styles.DescWhite}>
                  If things are not working out , we offer polite and clear drop down chat suggestions. We don’t need to ghost or drop out on someone. This built in feature is eay and reduces hurt feelings. Meadow is a positive community of people looking for the magic of love and relationship.
                </Typography>
              </Grid>

              <Grid sx={{ marginTop: 5 }}>
                <Typography className={styles.titleOrange}>
                  FAQ
                </Typography>
                <Typography className={styles.DescRed}>
                  What does Meadow Cost?
                </Typography>
                <Typography className={styles.DescWhite}>
                  Right now is free to use  ! We are looking to build a positive community of users, help us refer and connect great people to Meadow.
                </Typography>

                <Typography className={styles.DescRed}>
                  Is Meadow available all devices?
                </Typography>
                <Typography className={styles.DescWhite}>
                  Yes!  You can install Meadow on any device, Any web browser , or mobile phone. Meadow can currently only be found on our website www.meadow.love . You can install the app on your phone with the link below.
                </Typography>

                <Grid sx={{ marginTop: 4, width: '100%' }}>
                <Grid
                  border="1px solid #cccccc"
                  borderRadius="25px"
                  margin={'auto'}
                  paddingTop={'18px'}
                  paddingBottom={'13px'}
                  justifyContent='center'
                  maxWidth={'600px'}
                >
                  <Grid
                    item
                    xs={12}
                    justifyContent={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      onClick={() => {
                        handleDownloadAppClick();
                      }}
                      className={styles.downloadAppInstall}
                    >
                      Download and install
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      gap: '5px',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      style={{
                        float: `left`,
                      }}
                      className={styles.appStoreImg}>
                      <img
                        src={AppLogo}
                        alt="appstore link"
                        style={{
                          height: `${scaleByRatio(80)}px`,
                        }}
                        onClick={() => {
                          handleDownloadAppClick();
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
            </Grid>







            <Grid
              container
              fullWidth
              direction="column"
              sx={{
                padding: '40px 100px',
                backgroundColor: '#000',
                color: '#fff',
              }}
              className={`${styles.landingContactWrapper} ${styles.mobPadding}`}>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWork}
                  sx={{
                    fontSize: `${scaleByRatio(32)}px`,
                    lineHeight: `${scaleByRatio(38.4)}px`,
                    padding: `${scaleByRatio(20)}px ${scaleByRatio(20)}px`,
                  }}>
                  Contact Us
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWorkDescHead}
                  sx={{
                    fontSize: `${scaleByRatio(16)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                  }}>
                  Feel free to reach out to us with any questions or comments,
                  we love to get feedback and to help you in anyone we can. All
                  enquiries are answered in 24 hours.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <Typography
                  textAlign={'left'}
                  className={styles.howDoesItWorkDesc}
                  sx={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(29)}px`,
                  }}>
                  Email
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <InputField
                  name="Email"
                  id={'Email'}
                  value={email}
                  placeholder={'Email (Required)'}
                  handleChange={(evt) => handleEmailChange(evt)}
                  type={'text'}
                  class_name={styles.name}
                  minHeightPX={`${scaleByRatio(40)}`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <Typography
                  textAlign={'left'}
                  className={styles.howDoesItWorkDesc}
                  sx={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(29)}px`,
                  }}>
                  Message
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <InputField
                  name="Message"
                  id={'Message'}
                  value={message}
                  placeholder={'Message (Required)'}
                  handleChange={(evt) => handleMessageChange(evt)}
                  type={'text'}
                  class_name={styles.message}
                  minHeightPX={`${scaleByRatio(70)}`}
                  multiline={true}
                  rows={5}
                />
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}
                className={styles.submitBtn}>
                <CustomButton
                  text={'Submit'}
                  variant={ButtonVariant.contained}
                  type={ButtonType.primary}
                  onClick={handleOnSubmitClicked}
                />
              </Grid>
            </Grid>
          </Box>
          {/* )} */}
        </LandingPageLayout>
      )}
      <>
        {
          showIosDownload === true &&
          <>
            {showIphone ? (
              <Modal
                open={showIosDownload}
                onClose={() => setShowIosDownload(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box
                  sx={{
                    position: 'absolute',
                    top: showIphone ? '65%' : showMac ? '20%' : '15%',
                    left: showMac || showIpad ? '70%' : '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    maxWidth: 400,
                    bgcolor: '#181B20',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: '8px',
                  }}>
                  <img
                    src={AppLogo}
                    style={{ width: '108px', height: '32px' }}></img>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowIosDownload(false)}
                    sx={{
                      position: 'absolute',
                      top: 25,
                      right: 15,
                      color: '#22A887',
                      // borderRadius: '50%',
                      // border: '2px solid #22A887',
                      padding: '1px',
                    }}>
                    {/* <CloseIcon /> */}
                    <img src={DownloadCloseIcon} alt="icon" />
                  </IconButton>

                  <p style={{ color: '#BCBCBC' }}>
                    To install meadow on your phone, just follow these easy steps
                  </p>
                  <div className='phone-step-1'>
                    <div className='phone-step-1-head'>Step 1</div>
                    <div className='phone-step-1-desc'>Click on “ Share “  icon below</div>
                  </div>
                  {
                    showIphone &&
                    <div className='install-img-1'>
                      <img src={pwaInstall1} className='pwaInstall1 w-100' alt="imageNp" />
                    </div>
                  }
                  {
                    showMac &&
                    <div
                      className="d-flex"
                      style={{
                        marginBottom: '-55px',
                        marginLeft: '-5px',
                        display: 'flex',
                        marginRight: '20px',
                      }}>
                      <img
                        src={InstallUpArrow}
                        alt="img np"
                        style={{ marginRight: '7px' }}
                      />
                      <img src={MacAddIcon} alt="img np" />
                    </div>
                  }

                  {
                    isIPhone &&
                    <>
                      <div className='iphone-lower-div'>
                        <div className='div-1'>
                          <div className='div-1-parent'>
                            <div className='phone-step-1-head'>Step 2</div>
                            <div className='div-11'>Then scroll down and click on</div>
                          </div>
                          <div className='div-12'>
                            <div className='div-122'>“Add to Home Screen”</div>
                            <img
                              src={DownloadPlusIcon}
                              alt="download"
                              // style={{ marginBottom: '-5px' }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='div-3'>
                        <img src={pwaInstall2} className='pwa-install-2' alt="" />
                      </div>
                    </>
                  }

                  {
                    showMac &&
                    <>
                      <ol style={{ color: 'white', marginLeft: '20px' }}>
                        <li style={{ listStyle: 'none' }}>
                          {
                            isIPhone ? 'Then Scroll down and click on' : 'Then click on ,'
                          }
                        </li>
                        {
                          showMac &&
                          <li style={{ listStyle: 'none' }}>
                            <span style={{ color: '#22A887' }}>
                              {showMac ? 'Install' : 'Add to Home Screen'}
                            </span>
                            {!showMac && (
                              <span>
                                <img
                                  src={DownloadPlusIcon}
                                  alt="download"
                                  style={{ marginBottom: '-5px' }}
                                />
                              </span>
                            )}
                          </li>
                        }

                        {
                          isIPhone &&

                          <li style={{ listStyle: 'none' }}>
                            <span style={{ color: '#FE0000' }}>
                              "Add to Home Screen"
                            </span>
                          </li>
                        }
                      </ol>
                      <Button
                        onClick={() => setShowIosDownload(false)}
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 2,
                          color: '#22A887',
                          // borderRadius: '10%',
                          border: '2px solid #22A887',
                          backgroundColor: 'transparent',
                          width: '300px',
                          height: '46px',
                        }}>
                        Close
                      </Button>
                    </>
                  }
                </Box>
              </Modal>
            ) : (
              <Modal
                open={showIosDownload}
                onClose={() => setShowIosDownload(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box
                  sx={{
                    position: 'absolute',
                    top: showIphone ? '65%' : showMac ? '20%' : '15%',
                    left: showMac || showIpad ? '70%' : '60%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    maxWidth: 400,
                    bgcolor: '#181B20',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: '8px',
                  }}>
                  <img
                    src={AppLogo}
                    style={{ width: '108px', height: '32px' }}></img>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowIosDownload(false)}
                    sx={{
                      position: 'absolute',
                      top: 25,
                      right: 15,
                      color: '#22A887',
                      padding: '1px',
                    }}>
                    <img src={DownloadCloseIcon} alt="icon" />
                  </IconButton>

                  <p style={{ color: 'white' }}>
                    {`To install meadow on your device, just click on the “
                      ${showMac ? `Install` : showIpad ? 'above Share' : ''
                    } ” icon`}
                  </p>
                  <div
                    className="d-flex"
                    style={{
                      marginBottom: '-55px',
                      marginLeft: '-5px',
                      display: 'flex',
                      marginRight: '20px',
                    }}>
                    <img
                      src={
                        showIphone ? InstallDownArrow : InstallUpArrow
                      }
                      style={{ marginRight: '7px' }}
                      alt=""
                    />
                    <img src={showMac ? MacAddIcon : IphoneShare} alt="" />
                  </div>
                  <ol style={{ color: 'white', marginLeft: '20px' }}>
                    <li style={{ listStyle: 'none' }}>
                      Then click on ,
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <span style={{ color: '#22A887' }}>
                        {showMac ? 'Install' : 'Add to Home Screen'}
                      </span>
                      {!showMac && (
                        <span>
                          <img
                            src={DownloadPlusIcon}
                            alt="download"
                            style={{ marginBottom: '-5px' }}
                          />
                        </span>
                      )}
                    </li>
                  </ol>

                  <Button
                    onClick={() => setShowIosDownload(false)}
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      color: '#22A887',
                      border: '2px solid #22A887',
                      backgroundColor: 'transparent',
                      width: '300px',
                      height: '46px',
                    }}>
                    Close
                  </Button>
                </Box>
              </Modal>
            )}
          </>
        }
      </>
    </>
  );
};

export default LandingPage;
