export const ASSETS = {
  AppLogo: '../assets/AppLogo.svg',
};

export const routeUrls = {
  landingPage: '/',
  join: '/join',
  aboutus: '/aboutus',
  faq: '/faq',
  contactus: '/contactus',
  termsandconditions: '/terms-and-conditions',
  privacy: '/privacy-policy',
  cookiePolicy: '/cookie-policy',
  start: '/start',
  login: '/login',
  myProfile: '/my-profile-update',
  myProfileQuestion: '/profile-building-questions',
  dashboard: '/user-dashboard',
  uploadPhotos: '/upload-photos',
  downloadPWA: '/install-pwa',
  home: '/home',
  profile: '/profile',
  inviteFriend: '/invite-friend/:request_uid',
  workData: '/work-data',
  petData: '/pet-data',
  updateData: '/profile-update-data',
  daterUpdatedQuestion: '/dater-updated-questions',
  daterUpdatedSCQuestion: '/dater-questions',
  activitiesPause: '/activities-pause',
  logout: '/log-out',
  reportUser: '/report-user',
  Support: '/support',
  ShareApp: '/share-app',
  Elbows: '/elbows-friend',
  signinLoader: '/signin-loader',
  daterProfile: '/dater-profile',
};

//Report User

export const ReportData = [
  'Spam',
  'Pornography',
  'Hatred and bulling',
  'Child porn',
  'Self-harm Violent',
  'Deceptive content',
  'Gory and harmful content',
  'Illegal activities (e.g. drug uses)',
  'Copyright and trademark infringement',
  'Other',
];

export const landingHeaderMenu = [
  {
    path: routeUrls.contactus,
    label: 'Contact Us',
  },
  {
    path: routeUrls.faq,
    label: 'FAQ',
  },
  {
    path: routeUrls.aboutus,
    label: 'About Us',
  },
];

export const ISD_CODE = '+91';
export const errorMsg = {
  genericOTPError: 'OTP mismatch. Please try again.',
  genericPhoneNumberError: 'Please provide a valid phone number.',
};

export const startPageFlow = {
  signup: 'signup',
  login: 'login',
};

export const pendingFieldsName = {
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',
  q_whom_meet: 'q_whom_meet',
  dob: 'age',
  email_notification_enabled: 'email_notification_enabled',
  push_notification_enabled: 'push_notification_enabled',
  sms_notification_enabled: 'sms_notification_enabled',
};

export const pendingFieldsMapping = {
  first_name: 0,
  last_name: 1,
  email: 2,
  //'q_whom_meet':3,
  age: 3,
  email_notification_enabled: 4,
  push_notification_enabled: 4,
  sms_notification_enabled: 4,
  lets_get_started: 5,
};

export const AnswerType = {
  MC: 'MC',
  SC: 'SC',
  TF: 'TF',
  Range: 'Range',
  Select: 'Select',
};

export const PreferenceQuestionType = {
  Question: 'Question',
  Custom: 'Custom',
};

export const Q_Identifier_WhoWouldYouLikeToMeet = 15;
export const Q_Identifier_Height = 17;
export const Q_Identifier_Name = 1;

export const MyProfileQuestionsSequence = [
  {
    identifier: 13,
    name: 'gender',
    step: 1,
  },
  {
    identifier: 14,
    name: 'sexuality',
    step: 2,
  },
  {
    identifier: 16,
    name: 'ethinicity',
    step: 3,
  },
  {
    identifier: 17,
    name: 'height',
    step: 4,
  },
  {
    identifier: [18, 19],
    name: 'kids preference',
    step: 5,
  },
  {
    identifier: 20,
    name: 'spirituality',
    step: 6,
  },
  {
    identifier: 21,
    name: 'poliical belief',
    step: 7,
  },
  {
    identifier: null,
    name: 'Upload photos',
    step: 8,
  },
  {
    identifier: 22,
    name: 'work data',
    step: 9,
  },
  {
    identifier: [25, 26, 27],
    name: 'pet data',
    step: 10,
  },
  {
    identifier: null,
    name: 'Geo location',
    step: 11,
  },
];

export const HeightinFt = [
  {
    value: 4,
    displayText: "4'",
  },
  {
    value: 5,
    displayText: "5'",
  },
  {
    value: 6,
    displayText: "6'",
  },
  {
    value: 7,
    displayText: "7'",
  },
  {
    value: 8,
    displayText: "8'",
  },
  {
    value: 9,
    displayText: "9'",
  },
];
export const HeightinInch = [
  {
    value: 0,
    displayText: '0"',
  },
  {
    value: 1,
    displayText: '1"',
  },
  {
    value: 2,
    displayText: '2"',
  },
  {
    value: 3,
    displayText: '3"',
  },
  {
    value: 4,
    displayText: '4"',
  },
  {
    value: 5,
    displayText: '5"',
  },
  {
    value: 6,
    displayText: '6"',
  },
  {
    value: 7,
    displayText: '7"',
  },
  {
    value: 8,
    displayText: '8"',
  },
  {
    value: 9,
    displayText: '9"',
  },
  {
    value: 10,
    displayText: '10"',
  },
  {
    value: 11,
    displayText: '11"',
  },
];

export const DrawerWidth = 240;

export const HomeMenuConstant = {
  Explore: 1,
  ChatterBox: 2,
  Convos: 3,
  Elbows: 4,
  MyFriends: 5,
  DatingPreferences: 6,
  Notifications: 7,
  AccountSettings: 8,
  ShareApp: 9,
  Support: 10,
  TnC: 11,
  PrivacyPolicy: 12,
  Logout: 13,
  MyProfile: 14,
  MyDaters: 15,
  Report: 16,
  Dashboard: 17,
  DaterProfile: 18,
};

export const ProfilePageType = {
  Explore: 'Explore',
  MyProfile: 'MyProfile',
  Elbows: 'Elbows',
  Convos: 'Convos',
  ChatterBox: 'ChatterBox',
};

export const AlertTypes = {
  success: 'success',
  error: 'error',
};

export const ExploreViewHistoryStatus = {
  SKIP: 'S',
  NO: 'N',
  MAYBE: 'M',
  YES: 'Y',
};

export const ACCEPTANCE_STATUS_ELBOW = {
  Decline: 'D',
  ElbowSent: 'ES',
  Pending: 'P',
};

export const ONESIGNAL_APP_ID = '079a92e9-789f-44d3-bfbb-9de58f10acab';

export const ONESIGNAL_API_KEY =
  'YjQ4ZWFiMGMtZDBlZi00NDgzLTgzY2MtNjc3MTM4MmUxMzQ3';

export const PUB_NUB_PUBLISH = 'pub-c-e99504fc-a328-449d-a68c-0b537932bc07';

export const PUB_NUB_SUBSCRIBE = 'sub-c-66fa674b-537b-4ca1-b92c-3e34bf2d330c';

export const landingPage_how_does_it_work_desc = [
  'With Meadow , when you see someone you like there are two unique ways to make a connection.',
  '1. You can reach out to one of the friends you like to learn more.',
  'Or',
  '2. One of your friends can reach out to the person for you with your permission , we call that an elbow.',
  'If the friend you approach thinks it’s a match they can connect you through and make an Meadow. And if your friend thinks the person you asked them to connect to,  seems to be a match , they can make an Meadow to get you connected.',
  'If things are not working our the stakes are lower , you never had the awkward experience of being ghosted or not having any help in the experience. It’s just and easier more natural way to meet someone online.',
];

export const landingPage_capabilities = [
  {
    heading: 'Use our Chatterbox Feature',
    description:
      'As you and your friends are looking at potential people people you simply add then the Chatterbox , with a “yes” or “Maybe”. Then  via group chat you can review and discuss profiles together and take your time. Get feedback and discuss things , have fun with it and lower the pressure.',
  },
  {
    heading: 'Your Friends Need Your Permission',
    description:
      'Your friends can never reach out to someone without your permission. They must make what we call an Elbow request. You can either accept or decline that request depending on whether you want them to reach out or not.',
  },
  {
    heading: 'Relationship Insights Dashboard',
    description:
      'Break the ice and kickstart conversations effortlessly with our Icebreaker Questions feature. We provide a curated list of engaging and fun questions to help you start meaningful conversations with your matches. Say goodbye to awkward silences and hello to great conversations.',
  },
  {
    heading: 'Set your Dating Preferences',
    description:
      'Our advanced algorithm shows you the type of person you want to see based on a carefully curated and clear preferences you set. You can edit these as you go along to refine the people you are seeing.',
  },
  {
    heading: 'Saying Positive and Friendly',
    description:
      'If something is not working out we provide suggested replies to let someone know it’s just not in the cards. Meadow is a space that encourages respect and positivity.',
  },
  {
    heading: 'How Many Friends Can Help Me',
    description:
      'You can only have one or two friends help at any one time. We’ve tried to limit the means of contact and the means of contact to avoid confusion. It’s also what makes Meadow unique. Pick friends you trust with different points of view and experience, the power of group wisdom !',
  },
];

export const landingPageFAQ = [
  {
    id: 1,
    question: 'What is Meadow?',
    answer:
      'Meadow is a dating app designed to help people connect and find meaningful relationships. We provide a platform for meeting new people, exploring potential matches, and building connections.',
  },
  {
    id: 2,
    question: 'Is Meadow free to use?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non ligula sit amet odio feugiat euismod. Nulla ut libero at velit eleifend accumsan vel nec neque. Duis id finibus justo. Curabitur sit amet augue nec diam condimentum ornare non vitae nisl. Pellentesque vitae euismod mauris.',
  },
  {
    id: 3,
    question: 'Is Meadow available on all devices?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non ligula sit amet odio feugiat euismod. Nulla ut libero at velit eleifend accumsan vel nec neque. Duis id finibus justo. Curabitur sit amet augue nec diam condimentum ornare non vitae nisl. Pellentesque vitae euismod mauris.',
  },
  {
    id: 4,
    question: 'How do I create a profile?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non ligula sit amet odio feugiat euismod. Nulla ut libero at velit eleifend accumsan vel nec neque. Duis id finibus justo. Curabitur sit amet augue nec diam condimentum ornare non vitae nisl. Pellentesque vitae euismod mauris.',
  },
  {
    id: 5,
    question: 'What is a Payment Gateway?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non ligula sit amet odio feugiat euismod. Nulla ut libero at velit eleifend accumsan vel nec neque. Duis id finibus justo. Curabitur sit amet augue nec diam condimentum ornare non vitae nisl. Pellentesque vitae euismod mauris.',
  },
];

export const totalQuestions = 9;

export const APP_DRAWER_WIDTH = 309;
